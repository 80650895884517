import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicationManagementComponent } from './publication-management.component';
import { PublicationManagementRoutingModule } from './publication-management-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProductsPublicationComponent } from './products-publication/products-publication.component';
import { SuppliersPublicationComponent } from './suppliers-publication/suppliers-publication.component';
import { ProductPublicationCardComponent } from './products-publication/product-publication-card/product-publication-card.component';

@NgModule({
  declarations: [
    PublicationManagementComponent,
    SuppliersPublicationComponent,
    ProductsPublicationComponent,
    ProductPublicationCardComponent,
  ],
  imports: [CommonModule, PublicationManagementRoutingModule, SharedModule, TranslateModule.forChild({})],
})
export class PublicationManagementModule {}
