<div class="product-dashboard-wrapper">
  <!--Details and Cube-->
  <div class="product-details-holder d-flex flex-column">
    <div class="top-holder d-flex">
      <!--Details-->
      <div class="product-details">
        <h5 class="section-header">
          {{ 'Product Details' | translate }}
        </h5>
        <div class="pd-wrapper">
          <div class="img-file">
            <app-file-upload
              [placeholder]="product.image ? product.image : ('Logo' | translate)"
              [iconType]="'add_photo_alternate'"
              [imageUrl]="product.image"
              [readonly]="true"
            ></app-file-upload>
          </div>
          <div class="details-section">
            <div class="product-details-header">
              <h5>{{ product.name }}</h5>
              <div class="icon-holder">
                <div class="view-icon">
                  <app-custom-icon
                    id="view"
                    matTooltip="{{ 'View Product' | translate }}"
                    matTooltipClass="custom-tooltip-center centering"
                    matTooltipPosition="above"
                    [icon_category]="'security'"
                    [icon_name]="'eye'"
                    (click)="viewDetails()"
                    class="cursor-pointer"
                  >
                  </app-custom-icon>
                </div>
                <div class="edit-icon">
                  <app-custom-icon
                    id="edit"
                    matTooltip="{{ 'Edit Product' | translate }}"
                    matTooltipClass="custom-tooltip-center centering"
                    matTooltipPosition="above"
                    [icon_category]="'files'"
                    [icon_name]="'edit'"
                    (click)="openEditProductDialog()"
                    class="cursor-pointer"
                  >
                  </app-custom-icon>
                </div>
                <div class="delete-icon">
                  <app-custom-icon
                    id="deactivate"
                    matTooltip="{{ 'Deactivate Product' | translate }}"
                    matTooltipClass="custom-tooltip-center centering"
                    matTooltipPosition="above"
                    [icon_category]="'essential'"
                    [icon_name]="'deactivate-red'"
                    (click)="deactivateProduct(product)"
                    class="cursor-pointer"
                  >
                  </app-custom-icon>
                </div>
              </div>
            </div>
            <div class="description-holder">
              <div class="description">
                {{ 'Product Description' | translate }} :
                <span *ngIf="product.description; else noneblock">
                  <app-view-rich-text [content]="product.description"></app-view-rich-text>
                </span>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noneblock>
          <span class="">
            {{ 'None' | translate }}
          </span>
        </ng-template>
      </div>

      <!-- Environmental details -->
      <!--      <div class="env-details-holder">-->
      <!--        <div class="env-section">-->
      <!--          <p class="env-title">-->
      <!--            {{ 'Environmental details' | translate }}-->
      <!--          </p>-->
      <!--          <p class="env-description">-->
      <!--            {{ 'Add environmental details of your product, like CO2/H2O emissions.' | translate }}-->
      <!--          </p>-->
      <!--          <div class="add-details-btn">-->
      <!--            <app-rounded-button-->
      <!--              color="secondary"-->
      <!--              [button_text]="'Add details'"-->
      <!--              [icon_category]="'essential'"-->
      <!--              [icon_name]="'add'"-->
      <!--              [icon_right]="true"-->
      <!--            >-->
      <!--            </app-rounded-button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <!-- Project details START -->
    <app-product-project
      *ngIf="show_project"
      [product]="product"
      [projectsList]="projectsList"
      [projectsListOrg]="projectsListOrg"
      [has_subproject]="has_subproject"
      [has_project]="has_project"
      (onUpdateSuccess)="updateProduct()"
      (onUpdateError)="error()"
      [subprojectsList]="subprojectsList"
      [subprojectsListOrg]="subprojectsListOrg"
      [activeProjectsList]="activeProjectsList"
      [activeProjectsListOrg]="activeProjectsListOrg"
    ></app-product-project>
  </div>
  <mat-tab-group
    (selectedIndexChange)="onTabIndexChange()"
    (selectedTabChange)="onTabChange($event)"
    [(selectedIndex)]="selectedIndex"
    [class]="selectedIndex === 3 ? 'minHeightTab4' + selectedView : 'minHeightTab' + (selectedIndex + 1)"
  >
    <!-- Supply chains -->
    <mat-tab [label]="'Supply Chains & Batches' | translate">
      <ng-template matTabContent>
        <div class="product-supp">
          <div class="table-holder">
            <app-product-supply-chains
              *ngIf="product"
              [chains]="product.product_chains"
              [productUuid]="product.uuid"
              [product]="product"
              (isChains)="isChains = $event"
            ></app-product-supply-chains>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <!-- Suppliers -->
    <mat-tab [label]="'Suppliers' | translate">
      <ng-template matTabContent>
        <div class="product-supp">
          <div class="table-header">
            <h4 class="section-header">
              {{ 'Suppliers' | translate }}
            </h4>
            <!-- Add Supplier Dropdown-->
            <div class="assign-action d-flex align-items-center justify-content-center">
              <app-rounded-button
                class="add-supp-btn"
                color="secondary"
                [button_text]="'Add Supplier'"
                [icon_category]="'essential'"
                [icon_name]="'add-circle'"
                [icon_right]="true"
                size="md"
                (click)="scrollContent()"
                [matMenuTriggerFor]="beforeMenu"
              >
              </app-rounded-button>
            </div>

            <span #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="beforeMenu"></span>
            <mat-menu
              class="mat-menu-primary existing-project-menu"
              #beforeMenu="matMenu"
              xPosition="after"
              (closed)="clearFilter()"
            >
              <div class="menu-wrapper" (click)="$event.stopPropagation()">
                <div id="menu-text">
                  <p class="add-text">{{ 'Click on the supplier you want to add' | translate }}</p>
                </div>
                <div class="filterR">
                  <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
                    <mat-label class="label-text">{{ 'Search for supplier' | translate }}</mat-label>

                    <input (blur)="onBlur()" (input)="filterItem($event)" matInput placeholder="" #inputFilter />

                    <app-custom-icon
                      class="search-filter"
                      [icon_category]="'search'"
                      [icon_name]="'search-normal-2'"
                    ></app-custom-icon>
                  </mat-form-field>
                </div>
                <div class="item-holder">
                  <div *ngIf="existingSupplierList.length > 0">
                    <button
                      class="custom-item"
                      mat-menu-item
                      *ngFor="let supplier of existingSupplierList"
                      (click)="addExistingSupplier(supplier.uuid)"
                    >
                      <div class="menu-items">
                        <div class="supp-logo">
                          <div class="logo-wrapper">
                            <img
                              class="supp-img"
                              [src]="supplier.logo ?? 'assets/images/upload-placeholder-blue.svg'"
                              alt=""
                            />
                          </div>
                        </div>

                        <div class="supp-name">
                          <div class="name-wrapper">
                            <p class="name">{{ supplier.name }}</p>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>

                  <button *ngIf="existingSupplierList.length <= 0" class="custom-empty-item" mat-menu-item>
                    <div class="empty-holder">
                      <div class="icon-circle">
                        <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
                      </div>
                      <p class="d-flex align-items-center">{{ 'There are no suppliers created yet!' }}</p>
                    </div>
                  </button>
                </div>
                <div class="bottom-action">
                  <p class="info-text">{{ 'Can’t find the supplier you are looking for?' | translate }}</p>
                  <app-rounded-button
                    (click)="createSupplierDialog()"
                    color="primary"
                    button_text="Create Supplier"
                    size="md"
                  >
                  </app-rounded-button>
                </div>
              </div>
            </mat-menu>
          </div>
          <div class="table-holder">
            <app-product-suppliers
              [product]="product"
              [isAddSupplier]="isAddSupplier"
              (refresh)="refresh($event)"
              (toggleAdd)="isAddSupplier = $event"
              [allSuppliers]="allSuppliers"
              (existingSupplierList)="getExitingSuppliersList($event)"
            >
            </app-product-suppliers>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <!-- Product parts -->
    <!-- Product Certificates -->
    <mat-tab [label]="'Product Certificates' | translate">
      <ng-template matTabContent>
        <div class="product-supp">
          <div class="table-header">
            <h4 class="section-header">
              {{ 'Product Certificates' | translate }}
            </h4>
            <div class="filter d-flex">
              <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
                <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
                <input matInput (keyup)="_applyFilter($event)" placeholder="Quick search..." #input />
                <app-custom-icon
                  class="search-filter"
                  [icon_category]="'search'"
                  [icon_name]="'search-normal-2'"
                  matSuffix
                ></app-custom-icon>
              </mat-form-field>
            </div>
            <div class="product-section-btn">
              <div class="toggle-group">
                <mat-button-toggle-group #group="matButtonToggleGroup" (change)="onToggleChange($event)">
                  <div class="table-view toggle">
                    <mat-button-toggle value="tableView" aria-label="Table View" [checked]="true">
                      <app-custom-icon
                        class="cursor-pointer"
                        matTooltip="{{ 'List View' | translate }}"
                        matTooltipClass="custom-tooltip-center mb-16"
                        matTooltipPosition="above"
                        [icon_category]="'essential'"
                        [icon_name]="'menu-3lines'"
                      ></app-custom-icon>
                    </mat-button-toggle>
                  </div>
                  <div class="card-view toggle">
                    <mat-button-toggle value="cardView" aria-label="Card View">
                      <app-custom-icon
                        class="cursor-pointer"
                        matTooltip="{{ 'Grid View' | translate }}"
                        matTooltipClass="custom-tooltip-center mb-16"
                        matTooltipPosition="above"
                        [icon_category]="'grid'"
                        [icon_name]="'element-3-large'"
                      ></app-custom-icon>
                    </mat-button-toggle>
                  </div>
                </mat-button-toggle-group>
              </div>
              <div class="create-button section-header-btn cc-btn">
                <app-rounded-button
                  color="primary md"
                  [button_text]="'Create Certificate'"
                  [icon_category]="'learning'"
                  [icon_name]="'certificate'"
                  [icon_right]="true"
                  (click)="createCertificationDialog()"
                >
                </app-rounded-button>
              </div>
            </div>
          </div>
          <div class="table-holder">
            <app-product-certificates
              [id]="productId"
              [product]="product"
              [certification_product]="product?.certification_product ?? []"
              *ngIf="group.value === 'tableView'"
            ></app-product-certificates>

            <app-product-certificates-grid
              [id]="productId"
              [product]="product"
              [certification_product]="product?.certification_product ?? []"
              *ngIf="group.value === 'cardView'"
            ></app-product-certificates-grid>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <!-- Product Components -->
    <mat-tab [label]="'Product Components' | translate">
      <ng-template matTabContent>
        <mat-card class="p-0">
          <mat-card-content>
            <app-product-ingredients [add]="isAddIngredient" (toggleAdd)="isAddIngredient = $event" [product]="product">
            </app-product-ingredients>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </mat-tab>

    <!-- Details and Useful Data -->
    <mat-tab [label]="'Useful Data' | translate">
      <ng-template matTabContent>
        <!-- Useful Data -->
        <div class="use-data-cont">
          <div class="wrapper">
            <app-useful-data
              [product]="product"
              [typeUuid]="product.uuid"
              (refresh)="$event && getProduct()"
              [type]="'products'"
            >
            </app-useful-data>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <!-- Regulations -->
    <mat-tab [label]="'Regulations' | translate" *ngIf="displayRegulations" class="regTab">
      <ng-template matTabContent>
        <div class="use-data-cont">
          <div class="wrapper">
            <app-product-regulations [product]="product" (refresh)="$event && getProduct()"></app-product-regulations>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
