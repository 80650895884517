import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class ProductSetupDialogGroup {
  productPublicationSetupForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.productPublicationSetupForm = this._fb.group({
      is_description_private: [true],
      is_reference_private: [true],
    });
  }
}
