<div class="card d-flex justify-content-between">
  <div
    class="info d-flex"
    [ngClass]="{
      opacity: !product.is_display_on_brand_page || (product.is_display_on_brand_page && !product.is_published),
    }"
  >
    <img class="logo" [src]="product.image ? product.image : imgPlaceholder" alt="" />
    <div class="details">
      <div class="name-holder d-flex align-items-center">
        <p class="name m-0">{{ product.name }}</p>
      </div>

      <p class="supply-chains m-0" [ngClass]="{ published: product.is_published }">
        {{ product.is_published ? 'supply chain published' : ('no supply chains published' | translate) }}
      </p>
      <!-- TO DO in the next sprint -->
      <!-- <p class="language m-0" [ngClass]="{ 'additional-languages': product.additional_language !== 0 }">
        {{
          product.additional_language === 0
            ? 'no additional languages'
            : (product.additional_language + ' additional languages' | translate)
        }}
      </p> -->
      <p class="config m-0" [ngClass]="{ 'custom-config': !product.is_default_configuration }">
        {{ product.is_default_configuration ? 'default configuration' : ('custom configuration' | translate) }}
      </p>
      <div
        class="visibility d-flex align-items-center"
        [ngClass]="{ disabled: !product.is_published }"
        (click)="product.is_published && manageProductVisibility(product.uuid, product.is_display_on_brand_page)"
      >
        <p class="m-0">{{ 'visibility' }}</p>
        <app-custom-icon
          *ngIf="!product.is_display_on_brand_page || (product.is_display_on_brand_page && !product.is_published)"
          [icon_category]="'security'"
          [icon_name]="'hidden'"
        ></app-custom-icon>
        <app-custom-icon
          *ngIf="product.is_display_on_brand_page && product.is_published"
          [icon_category]="'security'"
          [icon_name]="'eye-slash'"
        ></app-custom-icon>
      </div>
    </div>
  </div>

  <app-custom-icon
    [icon_category]="'settings'"
    [icon_name]="'setting-4'"
    class="cursor-pointer"
    matTooltip="{{ 'Product visibility setup' | translate }}"
    matTooltipClass="custom-tooltip-center"
    matTooltipPosition="above"
    (click)="openSetupProduct(product)"
  ></app-custom-icon>
</div>
