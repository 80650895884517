<div class="add-site">
  <div *ngIf="supplier || suppSupplier" class="supplier-info d-flex align-items-center">
    <h6 class="m-0">
      <strong>{{ 'supply-chain-step.Creating supplier site for' | translate }}:</strong>
    </h6>
    <img [src]="(isSupplier ? suppSupplier?.logo : supplier?.logo) || 'assets/images/brand-avatar.png'" alt="" />
    <div class="text">
      <h5 class="m-0">
        <strong>{{ isSupplier ? suppSupplier?.name : supplier?.name }}</strong>
      </h5>
      <p class="p1-strong m-0">
        {{ isSupplier && suppSupplier?.city ? suppSupplier?.city + ',' : supplier?.city ? supplier?.city + ',' : '' }}
        {{ isSupplier ? suppSupplier?.country?.code : supplier?.country }}
      </p>
    </div>
  </div>

  <form [formGroup]="form" class="d-flex flex-wrap align-items-center">
    <mat-form-field class="input-primary" appearance="outline">
      <mat-label>{{ 'company-information.Site Name' | translate }}</mat-label>
      <input matInput formControlName="name" [placeholder]="'company-information.Site Name' | translate" required />
    </mat-form-field>
    <p
      class="p1-strong site-name-note"
      [innerHTML]="
        'company-information.Site name could be any facility: (headquarters, factory, farm, field, etc).' | translate
      "
    ></p>
    <mat-form-field class="input-primary" appearance="outline">
      <mat-label>{{ 'company-information.Country' | translate }}</mat-label>
      <input type="text" matInput formControlName="country" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
          {{ country.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="input-primary" appearance="outline">
      <mat-label>{{ 'company-information.Region' | translate }}</mat-label>
      <input matInput formControlName="region" [placeholder]="'company-information.Region' | translate" required />
    </mat-form-field>
    <mat-form-field class="input-primary" appearance="outline">
      <mat-label>{{ 'company-information.City' | translate }}</mat-label>
      <input matInput formControlName="city" [placeholder]="'company-information.City' | translate" required />
    </mat-form-field>
    <mat-form-field class="input-primary" appearance="outline">
      <mat-label>{{ 'company-information.Address line (Street no...)' | translate }}</mat-label>
      <input
        matInput
        formControlName="address"
        [placeholder]="'company-information.Address line (Street no...)' | translate"
        required
      />
    </mat-form-field>

    <div class="col-1" *ngIf="!isSupplier">
      <div class="is-main-location">
        <mat-radio-group formControlName="is_main_location">
          <mat-radio-button
            (click)="toggleMainAddress()"
            color="primary"
            [checked]="form.controls['is_main_location'].value === true"
          >
            {{ 'company-information.Make this the main site' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>
  <div class="action-buttons d-flex" *ngIf="!isSupplier">
    <app-rounded-button
      color="primary"
      [button_text]="'Save Site'"
      [size]="'md'"
      [icon_right]="true"
      [icon_category]="'buildings'"
      [icon_name]="'factory'"
      [disabled]="form && form.invalid"
      (click)="createSupplierSite()"
    >
    </app-rounded-button>
    <app-rounded-button color="secondary" [size]="'sm'" [button_text]="'Discard'" (click)="siteCreated.emit(null)">
    </app-rounded-button>
  </div>
  <div class="action-buttons d-flex pull-right" *ngIf="isSupplier">
    <app-rounded-button
      color="secondary"
      [size]="'sm'"
      [button_text]="'company-information.Cancel' | translate"
      (click)="siteCreated.emit(null)"
    >
    </app-rounded-button>
    <app-rounded-button
      class="create-site-btn"
      color="primary"
      [button_text]="'company-information.Create Site' | translate"
      [size]="'md'"
      [icon_right]="true"
      [icon_category]="'buildings'"
      [icon_name]="'factory'"
      [disabled]="form && (form.invalid || form.pristine)"
      (click)="createSupplierSite()"
    >
    </app-rounded-button>
  </div>
</div>
