import { Component, OnDestroy, OnInit } from '@angular/core';
import { IBrandListResult, IBrandUserResult } from '@app/core/interface/brands.interface';
import { DEFAULT_TAB_INDEX, NotificationType, SELECTED_TAB_INDEX_KEY, Storage, USER } from '@app/core/constants';
import { BrandManagementGroup } from './brand-management.group';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataStorageService } from '@service/data-localstorage.service';
import { IUser } from '@interface/login.interface';
import { InviteBrandUserComponent } from './invite-brand-user/invite-brand-user.component';
import { MatDialog } from '@angular/material/dialog';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UpdateBrandDetailsDialogComponent } from './update-brand-details-dialog/update-brand-details-dialog.component';

@Component({
  selector: 'app-brand-managment',
  templateUrl: './brand-management.component.html',
  styleUrls: ['./brand-management.component.scss'],
  providers: [BrandsFacade, RegisterFacade, BrandManagementGroup],
})
export class BrandManagementComponent implements OnInit, OnDestroy {
  brandsList: IBrandListResult[] = [];
  selectedBrand!: IBrandListResult | null;
  isNoteShowing = true;
  selectedTabIndex = 0;
  newInvitedUser!: IBrandUserResult;
  storedUser: IUser;
  logoAvatar = '../../../assets/images/brand-avatar.png';

  constructor(
    private _brandsFacade: BrandsFacade,
    private _router: Router,
    public dialog: MatDialog,
    private _dataStorageService: DataStorageService,
    private _snackbarService: SnackbarService
  ) {
    this.storedUser = this._dataStorageService.get(USER, Storage.local)
      ? JSON.parse(this._dataStorageService.get(USER, Storage.local))
      : JSON.parse(this._dataStorageService.get(USER, Storage.session));
  }

  ngOnInit() {
    const savedTabIndex = this._dataStorageService.get(SELECTED_TAB_INDEX_KEY, Storage.local);
    this.selectedTabIndex = savedTabIndex !== null ? parseInt(savedTabIndex, 10) : DEFAULT_TAB_INDEX;

    this.storedUser.brand &&
      this._brandsFacade.getBrand$(this.storedUser.brand).subscribe({
        next: this._getBrandSuccess.bind(this),
        error: this._error.bind(this),
      });
  }

  private _getBrandSuccess(data: IBrandListResult): void {
    if (data) {
      this.selectedBrand = data;
    } else {
      this._router.navigate(['brand-management/create-brand']);
    }
  }

  openUpdateBrandDetailsDialog() {
    const dialogRef = this.dialog.open(UpdateBrandDetailsDialogComponent, {
      width: '1160px',
      height: '90vh',
      panelClass: 'top-padding-0',
      data: this.selectedBrand,
    });

    dialogRef.afterClosed().subscribe((result: IBrandListResult) => {
      if (result) {
        this.selectedBrand = result;
      }
    });
  }

  openInviteBrandUserDialog() {
    const dialogRef = this.dialog.open(InviteBrandUserComponent, {
      width: '1160px',
      height: '90vh',
      panelClass: 'top-padding-0',
      data: this.selectedBrand,
    });

    dialogRef.afterClosed().subscribe((result: IBrandUserResult) => {
      if (result) {
        this.newInvitedUser = result;
        this.selectedBrand = null;
        this.selectedTabIndex = 0;
      }
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  onTabChange(index: number): void {
    this.selectedTabIndex = index;
    this._dataStorageService.set(SELECTED_TAB_INDEX_KEY, index.toString(), Storage.local);
  }

  ngOnDestroy() {
    // Optionally clear selected tab index from local storage
    this._dataStorageService.removeKey(SELECTED_TAB_INDEX_KEY, Storage.local);
  }
}
