<div class="supplier-wrap">
  <!--Details and Cube-->
  <div class="supplier-holder">
    <!--Details-->

    <div class="supplier-top-section supplier-details-holder">
      <div class="title-holder">
        <h6 class="section-header">
          {{ 'Supplier Details' | translate }}
        </h6>
      </div>

      <!-- <div class="cont-overflow"> -->
      <div class="supplier-details">
        <app-supplier-page-details
          [supplier]="supplier"
          (isEditDetails)="isEditDetails = $event"
          [disabled]="!isEditDetails"
          (refresh)="$event && getSupplier()"
          (show_buttons)="component_btn = $event"
        ></app-supplier-page-details>
      </div>
      <!-- </div> -->
    </div>

    <!--Cube-->

    <div class="supplier-top-section supplier-cub-holder">
      <div class="title-holder">
        <h6 class="section-header">
          {{ 'Supplier Cube' | translate }}
        </h6>
      </div>

      <div class="cub">
        <app-supplier-cube [supplier]="supplier"></app-supplier-cube>
      </div>
    </div>
  </div>
  <mat-tab-group
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="onTabChange($event)"
    [class]="selectedIndex === 3 ? 'minHeightTab4' + selectedView : 'minHeightTab' + (selectedIndex + 1)"
  >
    <!-- Active Batches -->
    <mat-tab [label]="'Supply Chains & Batches' | translate">
      <ng-template matTabContent>
        <div class="custom-card">
          <div class="cont-overflow">
            <div class="table-wrapper chains-batches">
              <app-chains-batches
                [showFilters]="false"
                [chainUuidList]="chainUuidList"
                [supplier]="supplier"
                [pageSize]="6"
              >
              </app-chains-batches>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <!--Supplier contacts-->
    <mat-tab [label]="'Contacts & Invited Users' | translate">
      <ng-template matTabContent>
        <div class="custom-card">
          <div class="card-header">
            <div class="toggle-holder">
              <h4 class="section-header">
                {{ 'Supplier Contacts & Invited Users' | translate }}
              </h4>

              <div class="toggle-group">
                <mat-button-toggle-group #group="matButtonToggleGroup">
                  <div class="toggle">
                    <mat-button-toggle value="contacts" aria-label="Supplier Contacts" [checked]="true">
                      <span>{{ 'Supplier Contacts' | translate }}</span>
                    </mat-button-toggle>
                  </div>
                  <div class="toggle">
                    <mat-button-toggle value="users" aria-label="Invited Users">
                      <span>{{ 'Invited Users' | translate }}</span>
                    </mat-button-toggle>
                  </div>
                </mat-button-toggle-group>
              </div>
            </div>

            <div class="supplier-manegement-btn" *ngIf="group.value === 'contacts'">
              <app-rounded-button
                color="primary"
                [button_text]="'Create New'"
                [icon_category]="'essential'"
                [icon_name]="'add-circle'"
                [size]="'md'"
                [icon_right]="true"
                (click)="addSupplierContact()"
              >
              </app-rounded-button>
            </div>
            <!--
            <div
              class="supplier-section-btn"
              *ngIf="group.value === 'users' && !environment.qa && !environment.production"
            >
              <app-rounded-button
                id="profile-add"
                color="turquoise"
                [button_text]="'Invite Users'"
                [icon_category]="'user'"
                [icon_name]="'profile-add'"
                [size]="'md'"
                [icon_right]="true"
                (click)="openEmailDialog()"
              >
              </app-rounded-button>
            </div> -->
          </div>

          <div class="cont-overflow">
            <div class="table-wrapper supp-contacts" *ngIf="group.value === 'contacts'">
              <app-supplier-contacts
                [supplier_uuid]="supplier?.uuid"
                [supplier_contacts]="supplier?.supplier_contacts ?? []"
                [languages]="languages"
                (fetchSupplierContacts)="getSupplier()"
              >
              </app-supplier-contacts>
            </div>
            <div class="table-wrapper supp-users" *ngIf="group.value === 'users'">
              <app-arena-account></app-arena-account>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <!-- Supplier sites -->
    <mat-tab [label]="'Supplier sites' | translate">
      <ng-template matTabContent>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="section-header">
              {{ 'Supplier sites' | translate }}
            </h4>
            <div class="supplier-manegement-btn">
              <app-rounded-button
                color="primary"
                [button_text]="'Create Site'"
                [icon_category]="'buildings'"
                [icon_name]="'factory'"
                [size]="'md'"
                [icon_right]="true"
                (click)="addFactory()"
              >
              </app-rounded-button>
            </div>
          </div>

          <div class="cont-overflow">
            <div class="table-wrapper">
              <app-supplier-sites
                [supplier_uuid]="supplier?.uuid"
                [supplier_sites]="supplier?.manufactories ?? []"
                (fetchSupplierSites)="getSupplier()"
              ></app-supplier-sites>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [label]="'Certificates' | translate">
      <ng-template matTabContent>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="section-header h-38">
              {{ 'Supplier Certificates' | translate }}
            </h4>
            <div class="filter d-flex">
              <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
                <input matInput (keyup)="_applyFilter($event)" placeholder="Quick search..." #input />
                <app-custom-icon
                  class="search-filter"
                  [icon_category]="'search'"
                  [icon_name]="'search-normal-2'"
                  matSuffix
                ></app-custom-icon>
              </mat-form-field>
            </div>
            <div class="supplier-section-btn">
              <div class="toggle-group">
                <mat-button-toggle-group #group="matButtonToggleGroup" (change)="onToggleChange($event)">
                  <div class="table-view toggle">
                    <mat-button-toggle
                      matTooltip="{{ 'List View' | translate }}"
                      matTooltipClass="custom-tooltip-center"
                      [matTooltipPosition]="'above'"
                      value="tableView"
                      aria-label="Table View"
                      [checked]="true"
                    >
                      <app-custom-icon [icon_category]="'essential'" [icon_name]="'menu-3lines'"></app-custom-icon>
                    </mat-button-toggle>
                  </div>
                  <div class="card-view toggle">
                    <mat-button-toggle
                      matTooltip="{{ 'Grid View' | translate }}"
                      matTooltipClass="custom-tooltip-center"
                      [matTooltipPosition]="'above'"
                      value="cardView"
                      aria-label="Card View"
                    >
                      <app-custom-icon [icon_category]="'grid'" [icon_name]="'element-3-large'"></app-custom-icon>
                    </mat-button-toggle>
                  </div>
                </mat-button-toggle-group>
              </div>
              <div class="button-checkbox">
                <app-rounded-button
                  (click)="createCertificationDialog()"
                  color="primary"
                  [button_text]="'Create Certificate'"
                  [icon_category]="'learning'"
                  [icon_name]="'certificate'"
                  [size]="'md'"
                  [icon_right]="true"
                >
                </app-rounded-button>
              </div>
            </div>
          </div>
          <div class="cont-overflow">
            <div class="table-wrapper">
              <app-supplier-certificates
                *ngIf="group.value === 'tableView'"
                (getSupplier)="getSupplier()"
                [uuid]="supplier?.uuid ?? ''"
                [certification_supplier]="supplier?.certification_supplier ?? []"
                [sites]="supplier?.manufactories ?? []"
              ></app-supplier-certificates>

              <app-supplier-certificates-grid
                *ngIf="group.value === 'cardView'"
                (getSupplier)="getSupplier()"
                [uuid]="supplier?.uuid ?? ''"
                [certification_supplier]="supplier?.certification_supplier ?? []"
                [sites]="supplier?.manufactories ?? []"
              ></app-supplier-certificates-grid>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <!--Supplier Ingredients-->
    <mat-tab [label]="'Components' | translate">
      <ng-template matTabContent>
        <div class="custom-card">
          <div class="card-header margin">
            <h4 class="section-header">
              {{ 'Supplier Components' | translate }}
            </h4>

            <div class="filter d-flex align-items-center mt-8">
              <mat-form-field
                class="input-primary mat-form-field-10-px-brd-rd custom-filter-header"
                appearance="outline"
              >
                <input matInput (keyup)="applyFilter($event)" placeholder="Quick search..." #input />
                <app-custom-icon
                  class="search-filter"
                  [icon_category]="'search'"
                  [icon_name]="'search-normal-2'"
                  matSuffix
                ></app-custom-icon>
              </mat-form-field>
            </div>
            <div class="header-btn-holder">
              <app-rounded-button
                color="primary"
                [size]="'md'"
                [icon_right]="true"
                [icon_category]="'essential'"
                [icon_name]="'box-2'"
                [button_text]="'Create component'"
                (click)="createIngredientDialog()"
                class="section-btn"
              >
              </app-rounded-button>
            </div>
          </div>
          <div class="checkbox margin-checkbox">
            <mat-checkbox class="checkbox-primary" (change)="showInactiveComponents($event)" [checked]="show_inactive">
              {{ 'show deactivated components' | translate }}
            </mat-checkbox>
          </div>

          <div class="cont-overflow">
            <div class="table-wrapper">
              <app-supplier-ingredients
                [dataSource]="dataSource"
                [ingredients]="supplier?.ingredients ?? []"
                [supplierUuid]="supplier?.uuid ?? ''"
                (refresh)="$event && getSupplier()"
                (show_buttons)="component_btn = $event"
                [component_btn]="component_btn"
              >
              </app-supplier-ingredients>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <!-- Useful Data -->
    <mat-tab [label]="'Useful Data' | translate">
      <ng-template matTabContent>
        <div class="use-data-cont">
          <div class="wrapper">
            <!-- <app-product-files-medias-links [product]="product" [typeUuid]="product?.uuid ?? ''"
              (refresh)="$event && ngOnInit()">
            </app-product-files-medias-links> -->

            <app-useful-data
              [product]="supplier"
              [manufactoryList]="supplier.manufactories"
              [typeUuid]="supplier?.uuid ?? ''"
              (refresh)="$event && ngOnInit()"
              [type]="'suppliers'"
            >
            </app-useful-data>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
