<div class="wrapper full-height">
  <mat-card>
    <div class="content-wrapper">
      <div *ngIf="selectedBrand" class="brand-data">
        <div class="tabs-section position-relative">
          <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChange($event.index)">
            <!-- Brand Details tab -->
            <mat-tab [label]="'Brand Details' | translate">
              <ng-template matTabContent>
                <div class="brand-details d-flex flex-column flex-wrap justify-content-between">
                  <div class="content-holder d-flex flex-wrap">
                    <div class="logo-name-industry col d-flex">
                      <div
                        class="logo-name d-flex align-items-center"
                        [ngClass]="selectedBrand.banner ? 'col-12' : 'col'"
                      >
                        <img [src]="selectedBrand.logo ? selectedBrand.logo : logoAvatar" [alt]="selectedBrand.name" />
                        <h6 class="flex-grow-1">
                          <strong>{{ selectedBrand.name }}</strong>
                        </h6>
                      </div>
                      <div
                        class="industry d-flex align-items-center"
                        [ngClass]="selectedBrand.banner ? 'col-12' : 'col'"
                      >
                        <h6>
                          <strong>{{ 'Industry' | translate }}</strong>
                        </h6>
                        <p>{{ selectedBrand.domain_activity.name }}</p>
                      </div>
                    </div>
                    <!-- <div
                        *ngIf="selectedBrand.banner"
                        class="banner-preview col"
                        [style.background-image]="'url(' + selectedBrand.banner + ')'"
                      ></div> -->
                    <div class="horizontal-line" [ngStyle]="{ 'margin-top': selectedBrand.banner && '24px' }"></div>
                    <div class="summary col">
                      <h6>
                        <strong>{{ 'Brand Description' | translate }}</strong>
                      </h6>
                      <p>
                        <!-- {{ selectedBrand.summary | translate }} -->
                        <app-view-rich-text [content]="selectedBrand.description"></app-view-rich-text>
                      </p>
                    </div>
                    <div class="description col">
                      <h6>
                        <strong>{{ 'Traceability Commitment' | translate }}</strong>
                      </h6>
                      <p>
                        <app-view-rich-text [content]="selectedBrand.traceability_commitment"></app-view-rich-text>
                      </p>
                    </div>
                  </div>
                  <div class="button-holder d-flex justify-content-end col-12">
                    <app-rounded-button
                      class="update-button"
                      color="turquoise"
                      button_text="Update Brand"
                      size="md"
                      (click)="openUpdateBrandDetailsDialog()"
                    >
                    </app-rounded-button>
                  </div>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </mat-card>
</div>
