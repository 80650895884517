import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IPublicationSupplier } from '@app/core/interface/publication-management.interface';
import { SupplierSetupDialogComponent } from '@app/module/publication-management/suppliers-publication/supplier-setup-dialog/supplier-setup-dialog.component';

@Component({
  selector: 'app-supplier-publication-card',
  templateUrl: './supplier-publication-card.component.html',
  styleUrl: './supplier-publication-card.component.scss',
})
export class SupplierPublicationCardComponent {
  @Input() supplier?: IPublicationSupplier;
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';
  constructor(private _dialog: MatDialog) {}

  openSetupSupplier() {
    this._dialog.open(SupplierSetupDialogComponent, {
      width: '1160px',
      height: '800px',
      panelClass: 'top-padding-0',
      data: {
        title: 'Supplier Setup',
        supplierUuid: this.supplier?.uuid,
        related_publicated_chain: this.supplier?.related_publicated_chain,
      },
    });
  }
}
