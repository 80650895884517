<div class="holder d-flex flex-column justify-content-between">
  <div class="info-card-wrap d-flex flex-column">
    <div class="header d-flex justify-content-between">
      <div class="info">
        <p class="m-0 info-text">
          {{
            'Here you can configure which fields and media will be visible for your products when published and which products will be visible on the brand page'
              | translate
          }}
        </p>
        <div class="visibility-holder d-flex align-items-center">
          <p class="m-0">{{ 'What does visibility mean?' | translate }}</p>
          <app-custom-icon
            [icon_category]="'essential'"
            [icon_name]="'info-circle'"
            class="cursor-pointer"
            matTooltip="{{
              'When the visibility is turned on, that product will be visible on your respect-code brand page. The visibility option is not available if there are no published chains for the product.'
                | translate
            }}"
            matTooltipClass="custom-tooltip-center"
            matTooltipPosition="above"
          ></app-custom-icon>
        </div>
      </div>

      <div class="filter">
        <mat-form-field class="input-primary mat-form-field-10-px-brd-rd custom-filter-header" appearance="outline">
          <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
          <input (keyup)="applyFilter($event)" matInput placeholder="Quick search..." #input />
          <app-custom-icon
            class="search-filter"
            [icon_category]="'search'"
            [icon_name]="'search-normal-2'"
          ></app-custom-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="cards-holder">
      <div class="card" *ngFor="let product of productsObservable$ | async">
        <app-product-publication-card
          [product]="product"
          (refresh)="getPublicationProducts()"
        ></app-product-publication-card>
      </div>
    </div>
  </div>

  <div class="footer">
    <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of products"></mat-paginator>
  </div>
</div>
