import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IManageProductVisibilityResponse,
  IPublicationProductsListResponse,
  IPublicationSupplierListResponse,
  IPublicationProductSetUp,
  IPublicationSupplierResponse,
} from '../interface/publication-management.interface';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PublicationManagementService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getPublicationSuppliersList$(): Observable<IPublicationSupplierListResponse> {
    return this._http.get<IPublicationSupplierListResponse>(`${this.apiUrl}/publication-management/suppliers/`);
  }

  getPublicationSupplierByUuid$(uuid: string): Observable<IPublicationSupplierResponse> {
    return this._http.get<IPublicationSupplierResponse>(
      `${this.apiUrl}/publication-management/supplier-setup/${uuid}/`
    );
  }

  updatePublicationSupplier$(
    uuid: string,
    data: IPublicationSupplierResponse
  ): Observable<IPublicationSupplierResponse> {
    return this._http.put<IPublicationSupplierResponse>(
      `${this.apiUrl}/publication-management/supplier-setup/${uuid}/`,
      data
    );
  }

  getPublicationProducts$(): Observable<IPublicationProductsListResponse> {
    return this._http.get<IPublicationProductsListResponse>(`${this.apiUrl}/publication-management/products/`);
  }

  manageProductVisibility$(uuid: string, data: FormData): Observable<IManageProductVisibilityResponse> {
    return this._http.patch<IManageProductVisibilityResponse>(
      `${this.apiUrl}/publication-management/products/${uuid}/`,
      data
    );
  }
  getProductSetup$(uuid: string): Observable<IPublicationProductSetUp> {
    return this._http.get<IPublicationProductSetUp>(`${this.apiUrl}/publication-management/product-setup/${uuid}/`);
  }

  updatePublicationProduct$(uuid: string, data: IPublicationProductSetUp): Observable<IPublicationProductSetUp> {
    return this._http.put<IPublicationProductSetUp>(
      `${this.apiUrl}/publication-management/product-setup/${uuid}/`,
      data
    );
  }
}
