import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class SupplierSetupDialogGroup {
  supplierPublicationSetupForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.supplierPublicationSetupForm = this._fb.group({
      is_name_private: [true],
      is_description_private: [true],
      is_hidden: [true],
      is_location_private: [true],
    });
  }
}
