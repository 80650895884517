<div class="content">
  <div class="tab-holder d-flex justify-content-between flex-column">
    <mat-tab-group #tabs>
      <mat-tab class="product-label">
        <ng-template mat-tab-label class="ps-0">
          {{ 'Products' | translate }}
        </ng-template>
        <div class="content-section">
          <app-products-publication></app-products-publication>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'Suppliers' | translate }}
        </ng-template>
        <div class="content-section">
          <app-suppliers-publication></app-suppliers-publication>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
