import { Component, Inject, OnInit, signal } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PublicationManagementFacade } from '@app/core/facade/publication-management.facade';
import {
  IMediaPayload,
  IPublicationSupplierResponse,
  ISiteLocation,
} from '@app/core/interface/publication-management.interface';
import { SupplierSetupDialogGroup } from './supplier-setup-dialog.group';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { NotificationType } from '@app/core/constants';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IMedia } from '@app/core/interface/medias.interface';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-supplier-setup-dialog',
  providers: [PublicationManagementFacade, SupplierSetupDialogGroup],
  templateUrl: './supplier-setup-dialog.component.html',
  styleUrl: './supplier-setup-dialog.component.scss',
})
export class SupplierSetupDialogComponent implements OnInit {
  supplier?: IPublicationSupplierResponse;
  form!: UntypedFormGroup;
  imgPlaceholder = 'assets/images/upload-placeholder-lightblue.png';

  linksPayload: IMediaPayload[] = [];
  mediaPayload: IMediaPayload[] = [];
  documentsPayload: IMediaPayload[] = [];

  isConfidential = signal(true);
  isFormLoaded = signal(false);
  readonly panelOpenState = signal(false);

  documents = signal<IMedia[]>([]);
  links = signal<IMedia[]>([]);
  medias = signal<IMedia[]>([]);
  siteLocations = signal<ISiteLocation[]>([]);
  mainLocation = signal<ISiteLocation | null>(null);
  images = signal<IMedia[]>([]);
  videos = signal<IMedia[]>([]);

  selectOptions = [
    { value: 4, expVal: 'street name, city name, region name, country name', viewValue: 'Address' },
    { value: 3, expVal: 'city name', viewValue: 'City' },
    { value: 2, expVal: 'region name', viewValue: 'Region' },
    { value: 1, expVal: 'country name', viewValue: 'Country' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { supplierUuid: string; title: string; related_publicated_chain: boolean },
    public dialogRef: MatDialogRef<IPublicationSupplierResponse>,
    private _publicationManagementFacade: PublicationManagementFacade,
    private _group: SupplierSetupDialogGroup,
    private _snackBarService: SnackbarService
  ) {
    this.form = this._group.supplierPublicationSetupForm;
  }

  ngOnInit(): void {
    this._loadSupplier();
  }

  onSubmit(): void {
    const payload = this.form.getRawValue();

    // Backend expects the payload to be in the following format and boolean values to be strings and case sensitive!!
    this.documentsPayload = this.documents().map(doc => ({
      uuid: doc.uuid,
      is_entity_private: doc.is_entity_private ? 'True' : 'False',
    }));
    this.linksPayload = this.links().map(link => ({
      uuid: link.uuid,
      is_entity_private: link.is_entity_private ? 'True' : 'False',
    }));
    this.mediaPayload = this.medias().map(media => ({
      uuid: media.uuid,
      is_entity_private: media.is_entity_private ? 'True' : 'False',
    }));

    payload.supplier_documents = this.documentsPayload;
    payload.supplier_links = this.linksPayload;
    payload.supplier_medias = this.mediaPayload;

    //We GET main location separately from the rest of the locations. We need to add it to the sites array for PUT request BE?!?!
    if (this.mainLocation()) {
      this.siteLocations.set([...this.siteLocations(), this.mainLocation() as ISiteLocation]);
    }

    payload.supplier_manufactories = this.siteLocations();

    if (this.supplier && payload) {
      this._publicationManagementFacade.updatePublicationSupplier$(this.supplier.uuid, payload).subscribe({
        next: () => {
          this._snackBarService.openTypeSnackbar('Publication setup updated successfully', NotificationType.success);
        },
        error: () => {
          this._snackBarService.openTypeSnackbar(
            `Error, please contact our support for further details`,
            NotificationType.error
          );
        },
      });
      this.dialogRef.close();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onToggleChange(field: 'is_name_private' | 'is_description_private', event: MatSlideToggleChange): void {
    this.form.get(field)?.setValue(!event.checked);
  }

  onHiddenChange(): void {
    if (this.isConfidential()) {
      this.isConfidential.set(false);
      const controls = ['is_name_private', 'is_description_private'].map(control => this.form.get(control));
      controls.forEach(control => {
        control?.setValue(false);
        control?.enable();
      });
    } else {
      this.isConfidential.set(true);
      this._setAllPrivate();
    }
  }

  onHiddenLocationChange(event: MatCheckboxChange): void {
    const selectInputControl = this.form.get('selectInput');
    if (event.checked) {
      selectInputControl?.disable();
      selectInputControl?.setValue('');
    } else {
      selectInputControl?.enable();
      if (this.form.get('selectInput')?.value === '' && this.supplier?.main_location.geopositioning) {
        const val = this.selectOptions.find(option => option.value === this.supplier?.main_location.geopositioning);
        selectInputControl?.setValue(val ?? this.selectOptions[4]);
      }
    }
  }

  toggleLink(uuid: string, link: Partial<IMedia>, checked: boolean): void {
    const index = this.links().findIndex(link => link.uuid === uuid);
    if (checked) {
      link.is_entity_private = false;
    } else {
      link.is_entity_private = true;
    }
    if (index !== -1) {
      const newLinks = [...this.links()];
      newLinks[index] = { ...newLinks[index], ...link };
      this.links.set(newLinks);
    }
  }

  toggleDocuments(uuid: string, document: Partial<IMedia>, checked: boolean): void {
    const index = this.documents().findIndex(document => document.uuid === uuid);
    if (checked) {
      document.is_entity_private = false;
    } else {
      document.is_entity_private = true;
    }
    if (index !== -1) {
      const newDocuments = [...this.documents()];
      newDocuments[index] = { ...newDocuments[index], ...document };
      this.documents.set(newDocuments);
    }
  }

  toggleMedias(uuid: string, media: Partial<IMedia>, checked: boolean): void {
    const index = this.medias().findIndex(media => media.uuid === uuid);
    if (checked) {
      media.is_entity_private = false;
    } else {
      media.is_entity_private = true;
    }
    if (index !== -1) {
      const newMedias = [...this.medias()];
      newMedias[index] = { ...newMedias[index], ...media };
      this.medias.set(newMedias);
    }
  }

  changeSiteLocation(selectedValue: number, siteUuid: string): void {
    const index = this.siteLocations().findIndex(sites => sites.uuid === siteUuid);
    const site = this.siteLocations()[index];
    site.geopositioning = selectedValue;
    if (index !== -1) {
      const newSites = [...this.siteLocations()];
      newSites[index] = { ...newSites[index], ...site };
      this.siteLocations.set(newSites);
    }
  }

  changeMainSiteLocation(selectedValue: number, mainSite: ISiteLocation): void {
    const newMainSite = { ...mainSite, geopositioning: selectedValue };
    this.mainLocation.set(newMainSite);
  }

  ////////////////////////////////////////////////////////////////Private methods///////////////////////////////////////////////////////////////

  private _setAllPrivate(): void {
    const newDocuments = this.documents().map(doc => ({ ...doc, is_entity_private: true }));
    const newLinks = this.links().map(link => ({ ...link, is_entity_private: true }));
    const newMedia = this.medias().map(media => ({ ...media, is_entity_private: true }));
    const newImages = this.images().map(image => ({ ...image, is_entity_private: true }));
    const newVideos = this.videos().map(video => ({ ...video, is_entity_private: true }));
    this.documents.set(newDocuments);
    this.links.set(newLinks);
    this.medias.set(newMedia);
    this.images.set(newImages);
    this.videos.set(newVideos);

    const controls = ['is_name_private', 'is_description_private', 'is_location_private'].map(control =>
      this.form.get(control)
    );
    controls.forEach(control => {
      control?.setValue(true);
      control?.disable();
    });
  }

  private _loadSupplier() {
    this._publicationManagementFacade.getPublicationSupplierByUuid$(this.data.supplierUuid).subscribe({
      next: resp => {
        this.supplier = resp;

        this.isConfidential.set(this.supplier.is_hidden);
        this.documents.set(this.supplier.documents);
        this.links.set(this.supplier.links);
        this.medias.set(this.supplier.medias);
        this.siteLocations.set(this.supplier.sites_locations);
        this.mainLocation.set(this.supplier.main_location);
        this.images.set(this.medias().filter(el => el.mimetype?.includes('image')));
        this.videos.set(this.medias().filter(el => el.mimetype?.includes('video')));

        this._patchFormFromResponseValues();
        this.isFormLoaded.set(true);
      },
      error: error => {
        this._snackBarService.openTypeSnackbar(`Error, ${error.detail}`, NotificationType.error);
        this.dialogRef.close();
      },
    });
  }

  private _patchFormFromResponseValues(): void {
    this.form.patchValue({
      is_hidden: this.supplier?.is_hidden,
      is_description_private: this.supplier?.is_description_private,
      is_name_private: this.supplier?.is_name_private,
      is_location_private: this.supplier?.is_location_private,
      selectInput: this.supplier?.is_location_private
        ? null
        : this.selectOptions.find(row => row.value == this.supplier?.main_location.geopositioning),
    });
  }
}
