import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { PublicationManagementFacade } from '@app/core/facade/publication-management.facade';
import {
  IPublicationSupplier,
  IPublicationSupplierListResponse,
} from '@app/core/interface/publication-management.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { Observable } from 'rxjs';
import { applyFilter } from '@app/core/utils/apply-filter';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-suppliers-publication',
  templateUrl: './suppliers-publication.component.html',
  styleUrl: './suppliers-publication.component.scss',
  providers: [PublicationManagementFacade],
})
export class SuppliersPublicationComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  publicationSuppliersList: IPublicationSupplier[] = [];
  publicationSuppliersObservable$!: Observable<IPublicationSupplier[]>;
  cardDataSource = new MatTableDataSource<IPublicationSupplier>();
  small_screen = false;

  constructor(
    private _publicationManagementFacade: PublicationManagementFacade,
    private _snackbarService: SnackbarService,
    private _changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.getPublicationSuppliersList();
  }

  getPublicationSuppliersList(): void {
    this._publicationManagementFacade.getPublicationSuppliersList$().subscribe({
      next: this._getPublicationSuppliersListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngAfterViewInit() {
    this.cardDataSource.paginator = this.paginator;
  }

  private _getPublicationSuppliersListSuccess(data: IPublicationSupplierListResponse): void {
    if (data && data.results.length) {
      this.publicationSuppliersList = data.results;
      this.cardDataSource.data = data.results;
      this._changeDetectorRef.detectChanges();
      this.detectScreen();
    }
  }

  detectScreen() {
    this.breakpointObserver
      .observe([
        '(max-width: 1365px)', // Small screens
        '(min-width: 1366px) and (max-width: 1920px)', // Medium screens
        '(min-width: 1921px)', // Large screens
      ])
      .subscribe((result: BreakpointState) => {
        if (result.breakpoints['(max-width: 1365px)']) {
          this.small_screen = true;
          this.paginator.pageSize = 9;
        } else if (result.breakpoints['(min-width: 1366px) and (max-width: 1920px)']) {
          this.small_screen = false;
          this.paginator.pageSize = 8;
        } else if (result.breakpoints['(min-width: 1921px)']) {
          this.small_screen = false;
          this.paginator.pageSize = 12;
        }

        this.publicationSuppliersObservable$ = this.cardDataSource.connect();
        this.cardDataSource.paginator = this.paginator;
      });
  }
  applyFilter(event: Event): void {
    applyFilter(event, this.cardDataSource);
  }
  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}
