import { Injectable } from '@angular/core';
import { PublicationManagementService } from '../service/publication-management.service';
import { catchError, map, Observable, ObservableInput, take, throwError } from 'rxjs';
import {
  IManageProductVisibilityResponse,
  IPublicationProduct,
  IPublicationProductsListResponse,
  IPublicationSupplierListResponse,
  IPublicationProductSetUp,
  IPublicationSupplierResponse,
} from '../interface/publication-management.interface';

@Injectable()
export class PublicationManagementFacade {
  constructor(private _publicationManagementService: PublicationManagementService) {}

  getPublicationSuppliersList$(): Observable<IPublicationSupplierListResponse> {
    return this._publicationManagementService.getPublicationSuppliersList$().pipe(
      take(1),
      catchError(
        ({ error }: IPublicationSupplierListResponse): ObservableInput<IPublicationSupplierListResponse> =>
          throwError(() => error)
      )
    );
  }

  getPublicationSupplierByUuid$(uuid: string): Observable<IPublicationSupplierResponse> {
    return this._publicationManagementService.getPublicationSupplierByUuid$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IPublicationSupplierResponse> => throwError(() => err.error))
    );
  }

  updatePublicationSupplier$(
    uuid: string,
    data: IPublicationSupplierResponse
  ): Observable<IPublicationSupplierResponse> {
    return this._publicationManagementService.updatePublicationSupplier$(uuid, data).pipe(
      take(1),
      catchError((err): ObservableInput<IPublicationSupplierResponse> => throwError(() => err.error))
    );
  }

  getPublicationProducts$(): Observable<IPublicationProductsListResponse> {
    return this._publicationManagementService.getPublicationProducts$().pipe(
      map(response => ({
        ...response,
        results: response.results.sort((a, b) => this.getPriority(b) - this.getPriority(a)),
      })),
      take(1),
      catchError(
        ({ error }: IPublicationProductsListResponse): ObservableInput<IPublicationProductsListResponse> =>
          throwError(() => error)
      )
    );
  }
  manageProductVisibility$(uuid: string, data: FormData): Observable<IManageProductVisibilityResponse> {
    return this._publicationManagementService.manageProductVisibility$(uuid, data).pipe(
      take(1),
      catchError(
        ({ error }: IManageProductVisibilityResponse): ObservableInput<IManageProductVisibilityResponse> =>
          throwError(() => error)
      )
    );
  }

  private getPriority(product: IPublicationProduct): number {
    if (product.is_display_on_brand_page && product.is_published && !product.is_default_configuration) {
      return 4; // Condition (a): Visible, Published, Custom Configuration
    } else if (product.is_display_on_brand_page && product.is_published && product.is_default_configuration) {
      return 3; // Condition (b): Visible, Published, Default Configuration
    } else if (product.is_display_on_brand_page && !product.is_published) {
      return 2; // Condition (c): Visible, Not Published in Supply Chain
    } else {
      return 1; // Condition (d): All other products
    }
  }

  getProductSetup$(uuid: string): Observable<IPublicationProductSetUp> {
    return this._publicationManagementService.getProductSetup$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: IPublicationProductSetUp): ObservableInput<IPublicationProductSetUp> => throwError(() => error)
      )
    );
  }

  updatePublicationProduct$(uuid: string, data: IPublicationProductSetUp): Observable<IPublicationProductSetUp> {
    return this._publicationManagementService.updatePublicationProduct$(uuid, data).pipe(
      take(1),
      catchError((err): ObservableInput<IPublicationProductSetUp> => throwError(() => err.error))
    );
  }
}
