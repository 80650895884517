@defer (when isFormLoaded()) {
  <div class="holder d-flex flex-column justify-content-between">
    <div class="header d-flex align-items-center">
      <h3 class="m-0">{{ 'Product setup' | translate }}</h3>
    </div>
    <div class="content d-flex flex-column">
      <div class="title d-flex flex-column">
        <div class="product d-flex align-items-center">
          <p class="m-0 p-affected">{{ 'Product affected by this setup:' | translate }}</p>
          <img class="logo" [src]="product.image ? product.image : imgPlaceholder" alt="" />
          <p class="m-0 p-name">{{ product.name }}</p>
        </div>
        <div class="info d-flex align-items-center">
          <app-custom-icon
            [ngClass]="is_published ? 'green' : 'yellow'"
            icon_category="essential"
            [icon_name]="is_published ? 'tick-circle' : 'slash'"
          ></app-custom-icon>
          <p *ngIf="is_published" class="m-0">
            {{
              'This product have a published chain. If you make any changes now you can update all of the affected chains in the pending updates section.'
                | translate
            }}
          </p>
          <p *ngIf="!is_published" class="m-0">
            {{
              'Please note that this product does not have any chains published yet. Once you publish a supply chain for this product, the setup that is done here will be applied fot this particular product. Any additional changes later will go into the pending updates section were you will be able to update all of the chains that are affected.'
                | translate
            }}
          </p>
        </div>
      </div>
      <div class="product-toggle-section d-flex flex-column">
        <p class="m-0">{{ 'Setup which fields will be visible for your product' | translate }}</p>
        <form [formGroup]="form" class="flex-grow-1">
          <div>
            <!-- Toggle fields -->
            <div class="form-wrapper d-flex flex-column">
              <div class="toggle-wrapper d-flex align-items-center">
                <div class="label">{{ 'Product description' | translate }}</div>
                <mat-slide-toggle
                  [checked]="!form.get('is_description_private')?.value"
                  (change)="onToggleChange('is_description_private', $event)"
                ></mat-slide-toggle>
              </div>
              <div class="toggle-wrapper d-flex align-items-center">
                <div class="label">{{ 'Product reference number' | translate }}</div>
                <mat-slide-toggle
                  [checked]="!form.get('is_reference_private')?.value"
                  (change)="onToggleChange('is_reference_private', $event)"
                ></mat-slide-toggle>
              </div>
              <!-- Toggles for custom fields. -->
              @for (item of customFields(); track $index) {
                <div class="toggle-wrapper d-flex align-items-center">
                  <div class="label">{{ item.name.name }}</div>
                  <mat-slide-toggle
                    [checked]="!item.is_field_private"
                    (change)="toggleCustomField(item.uuid, item, $event.checked)"
                  ></mat-slide-toggle>
                </div>
              }
            </div>
          </div>
        </form>
      </div>
      <div class="media-main-holder d-flex flex-column">
        <p class="m-0 info-title">{{ 'Which of the product useful data will be visible' | translate }}</p>
        <div class="media-holder d-flex flex-column">
          <div *ngIf="product.documents.length" class="data-wrap d-flex flex-column">
            <h6 class="data-title m-0">{{ 'Documents' | translate }}</h6>
            <div class="card-wrap">
              @for (item of documents(); track $index) {
                <div class="data-card d-flex flex-column">
                  <div class="d-flex justify-content-center align-items-center">
                    <img *ngIf="item.thumbnail" class="data-image" [src]="item.thumbnail" alt="" />
                    <div
                      *ngIf="!item.thumbnail"
                      class="doc-placeholder d-flex align-items-center justify-content-center"
                    >
                      <app-custom-icon icon_category="finance" icon_name="document"></app-custom-icon>
                    </div>
                  </div>
                  <div class="card-info d-flex flex-column justify-content-between align-items-center">
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="data-name m-0">{{ item.name }}</p>
                    </div>

                    <div class="toggle-wrap d-flex align-items-center">
                      <p class="m-0">{{ !item.is_entity_private ? 'On' : ('Off' | translate) }}</p>
                      <mat-slide-toggle
                        class="custom-slide-toggle"
                        [color]="'primary'"
                        [checked]="!item.is_entity_private"
                        (change)="toggleDocuments(item.uuid, item, $event.checked)"
                      >
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div *ngIf="images.length" class="data-wrap d-flex flex-column">
            <h6 class="data-title m-0">{{ 'Photos' | translate }}</h6>
            <div class="card-wrap">
              @for (item of medias(); track $index) {
                <div class="data-card d-flex flex-column" *ngIf="item.mimetype.indexOf('image') > -1">
                  <div class="d-flex justify-content-center align-items-center">
                    <img class="data-image" [src]="item.file ? item.file : imgPlaceholder" alt="" />
                  </div>
                  <div class="card-info d-flex flex-column justify-content-between align-items-center">
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="data-name m-0">{{ item.name }}</p>
                    </div>

                    <div class="toggle-wrap d-flex align-items-center">
                      <p class="m-0">{{ !item.is_entity_private ? 'On' : ('Off' | translate) }}</p>
                      <mat-slide-toggle
                        class="custom-slide-toggle"
                        [color]="'primary'"
                        [checked]="!item.is_entity_private"
                        (change)="toggleMedias(item.uuid, item, $event.checked)"
                      >
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div *ngIf="videos.length" class="data-wrap d-flex flex-column">
            <h6 class="data-title m-0">{{ 'Videos' | translate }}</h6>
            <div class="card-wrap">
              @for (item of medias(); track $index) {
                <div *ngIf="item.mimetype.indexOf('video') > -1" class="data-card d-flex flex-column">
                  <div class="d-flex justify-content-center align-items-center">
                    <video *ngIf="item.thumbnail" class="data-image" [src]="item.thumbnail" alt=""></video>
                    <div
                      *ngIf="!item.thumbnail"
                      class="doc-placeholder d-flex align-items-center justify-content-center"
                    >
                      <app-custom-icon icon_category="essential" icon_name="video"></app-custom-icon>
                    </div>
                  </div>
                  <div class="card-info d-flex flex-column justify-content-between align-items-center">
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="data-name m-0">{{ item.name }}</p>
                    </div>

                    <div class="toggle-wrap d-flex align-items-center">
                      <p class="m-0">{{ !item.is_entity_private ? 'On' : ('Off' | translate) }}</p>
                      <mat-slide-toggle
                        class="custom-slide-toggle"
                        [color]="'primary'"
                        [checked]="!item.is_entity_private"
                        (change)="toggleMedias(item.uuid, item, $event.checked)"
                      >
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div *ngIf="product.links.length" class="data-wrap d-flex flex-column">
            <h6 class="data-title m-0">{{ 'Links' | translate }}</h6>

            <div class="card-wrap">
              @for (item of links(); track $index) {
                <div class="data-card d-flex flex-column">
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="doc-placeholder d-flex align-items-center justify-content-center">
                      <app-custom-icon icon_category="essential" icon_name="link"></app-custom-icon>
                    </div>
                  </div>
                  <div class="card-info d-flex flex-column justify-content-between align-items-center">
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="data-name m-0">{{ item.name }}</p>
                    </div>

                    <div class="toggle-wrap d-flex align-items-center">
                      <p class="m-0">{{ !item.is_entity_private ? 'On' : ('Off' | translate) }}</p>
                      <mat-slide-toggle
                        class="custom-slide-toggle"
                        [color]="'primary'"
                        [checked]="!item.is_entity_private"
                        (change)="toggleLink(item.uuid, item, $event.checked)"
                      >
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div
          *ngIf="!product.medias.length && !product.links.length && !product.documents.length"
          class="no-media d-flex align-items-center"
        >
          <app-custom-icon icon_category="finance" icon_name="document"></app-custom-icon>
          <app-custom-icon icon_category="essential" icon_name="image"></app-custom-icon>
          <p>{{ 'There are no documents, media or links uploaded yet.' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="footer d-flex justify-content-end align-items-center">
      <app-rounded-button
        class="underline-button"
        [button_text]="'Cancel'"
        [size]="'sm'"
        color="tetriary-link"
        (click)="cancel()"
      ></app-rounded-button>
      <app-rounded-button
        [button_text]="'Save product setup'"
        [icon_category]="'arrows'"
        [icon_name]="'arrow-right'"
        [icon_right]="true"
        [size]="'md'"
        color="turquoise"
        (click)="onSubmit()"
      ></app-rounded-button>
    </div>
  </div>
} @loading {
  Loading...
} @error {
  Error...
}
